<template>
  <section class="loader">
    <div class="page-header">
      <h3 class="page-title">
        Loaders
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">UI Elements</a></li>
          <li class="breadcrumb-item active" aria-current="page">Loaders</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Loaders</h4>
            <p class="card-description">A collection of awesome customizable loaders</p>
            <div class="row">
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="square-box-loader">
                    <div class="square-box-loader-container">
                      <div class="square-box-loader-corner-top"></div>
                      <div class="square-box-loader-corner-bottom"></div>
                    </div>
                    <div class="square-box-loader-square"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="pixel-loader"></div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="bar-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="square-path-loader"></div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="dot-opacity-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="flip-square-loader mx-auto"></div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="moving-square-loader"></div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="loader-demo-box">
                  <div class="circle-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'loader'
}
</script>
